<script setup>
import {defineProps, defineEmits, ref, computed, onMounted, defineExpose} from 'vue'
import Multiselect from '@vueform/multiselect'
import {debounce} from '@/helpers/debounce'
import {useNewAdAccountRequestStore} from '@/views/ad-account-requests/AdAccountRequestStore'
import {useNotification} from '@kyvg/vue3-notification'

const {notify} = useNotification()
const {getAdAccounts} = useNewAdAccountRequestStore()

// Emits & props
const emit = defineEmits(['changed', 'update:modelValue'])
const props = defineProps({
  modelValue: {},
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: 'Enter from 2 characters to search',
  },
  listBySearch: {
    type: Boolean,
    default: false,
  },
  payload: {
    type: Object,
    default: null,
  },
  valueToReturn: {
    type: String,
    default: 'id',
  },
  multiple: {
    type: Boolean,
    default: false,
  },
})

// Refs
const adAccounts = ref([])
const loading = ref(false)
const mode = ref('single')

// Computed
const isValid = computed(() => {
  if (props.multiple === true) {
    return !props.required || props.modelValue.length !== 0
  }

  return !props.required || !!props.modelValue
})

const adAccountsOptions = computed(() => {
  return adAccounts.value.map(({id, title, external_id, can_has_top_up, platform}) => {
    let disabled = props.payload?.with_can_has_top_up && !can_has_top_up

    return {
      value: props.valueToReturn === 'id' ? id : external_id,
      label:
        (disabled ? '[Not allowed] ' : '') + `[${formatPlatform(platform)}]` + ' ' + `${title} [ID: ${external_id}]`,
      disabled,
    }
  })
})

// Methods
onMounted(async () => {
  await init()
})

const init = async () => {
  if (props.multiple === true) {
    mode.value = 'tags'
  }

  if (props.listBySearch) {
    await requestGetAdAccounts()
  }
}

const requestGetAdAccounts = async search => {
  try {
    adAccounts.value = await getAdAccounts({search, ...props.payload, with: ['company']})
  } catch (responseError) {
    notify({type: 'error', text: responseError.data.message})
  } finally {
    loading.value = false
  }
}

const formatPlatform = item => {
  let platform = item

  if (platform === 'Facebook') {
    platform = 'Meta'
  }

  if (platform === 'Bing') {
    platform = 'Microsoft Ads'
  }

  return platform.toUpperCase().trim()
}

const checkSearchValue = searchValue => {
  if (searchValue.length >= 2) {
    reloadAdAccounts(searchValue)
  }
}

const reloadAdAccounts = debounce(async searchValue => {
  loading.value = true

  await requestGetAdAccounts(searchValue)
}, 300)

const onChange = value => {
  emit('update:modelValue', value)
  emit('changed', structuredClone(adAccounts.value.find(n => n.id === value)))
}

defineExpose({checkSearchValue})
</script>

<template>
  <div :class="['the-select-wrapper', isValid ? 'valid' : 'invalid', mode === 'tags' ? 'tags' : '']">
    <input v-if="required && !isValid" class="d-none" required />
    <Multiselect
      :value="modelValue"
      ref="multiSelect"
      :options="adAccountsOptions"
      :disabled="disabled"
      :mode="mode"
      searchable
      :placeholder="listBySearch ? 'Select ad account' : placeholder"
      :loading="loading"
      @input="onChange"
      @search-change="checkSearchValue"
    >
      <template #clear><span /></template>
    </Multiselect>
  </div>
</template>
