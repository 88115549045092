<script setup>
import {computed} from 'vue'
import {useRoute, useRouter} from 'vue-router'

const route = useRoute()
const router = useRouter()

// Computed
const breadcrumbs = computed(() => {
  const trail = []
  let currentRoute = route

  while (currentRoute) {
    if (currentRoute.meta?.title) {
      trail.unshift({
        title: currentRoute.meta.title,
        path: currentRoute.path,
      })
    }

    const parentName = currentRoute.meta?.parent
    currentRoute = parentName ? router.getRoutes().find(r => r.name === parentName) : null
  }

  return trail
})
</script>

<template>
  <nav v-if="breadcrumbs.length" aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li
        :class="['breadcrumb-item d-flex align-items-center gap-2', index >= breadcrumbs.length - 1 ? 'px-1' : 'px-2']"
        v-for="(breadcrumb, index) in breadcrumbs"
        :key="breadcrumb.path"
      >
        <router-link v-if="index < breadcrumbs.length - 1" :to="breadcrumb.path">
          <h4 class="fw-bold main__title">
            {{ breadcrumb.title }}
          </h4>
        </router-link>
        <h4 v-else class="breadcrumb-item active fw-bold main__title" aria-current="page">
          {{ breadcrumb.title }}
        </h4>
        <h4 v-if="index < breadcrumbs.length - 1" class="fw-bold main__title active">/</h4>
      </li>
    </ol>
  </nav>
</template>

<style>
.breadcrumb-item::before {
  display: none;
}
</style>
