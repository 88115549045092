<script setup>
import {Modal as BaseModal} from 'bootstrap'
import {defineEmits, defineProps, onMounted, ref} from 'vue'
import {notify} from '@kyvg/vue3-notification'
import BaseForm from '@/components/BaseForm.vue'
import BaseCheckbox from '@/components/BaseCheckbox.vue'
import ApiErrors from '@/components/ui/ApiErrors.vue'
import TheSelectAdAccounts from '@/components/ui/TheSelectAdAccounts.vue'
import {useAdAccountRenameRequestStore} from '@/views/ad-account-requests/rename/AdAccountRenameRequestStore'
import {storeToRefs} from 'pinia'
import {useAuthenticationStore, usePointBoardStore} from '@/stores'
import TheSelect from '@/components/ui/TheSelect.vue'

const {getEmployees} = usePointBoardStore()
const {createAdAccountRenameRequest} = useAdAccountRenameRequestStore()
const {userCompanyHash} = storeToRefs(useAuthenticationStore())

// Emits & props
const emit = defineEmits(['created', 'updated', 'hidden'])
const props = defineProps({
  id: {
    type: String,
    default: null,
  },
})
const modalId = ref(props.id ?? 'ad-account-rename-request-modal')

// Refs
const modal = ref()
const defaultFormData = {name: '', entity_id: null, entity_type: null, reassign_buyer: false, new_buyer: null}
const formData = ref(structuredClone(defaultFormData))
const error = ref(null)
const loading = ref(false)
const buyerOptions = ref([])
const selectedAdAccount = ref(null)

// Methods
onMounted(() => {
  let modalElement = document.getElementById(modalId.value)
  modal.value = new BaseModal(modalElement)

  modalElement.addEventListener('hidden.bs.modal', () => {
    emit('hidden')
  })

  openModal()
})

const openModal = () => {
  modal.value.show()
}

const closeModal = () => {
  modal.value.hide()
  error.value = null
}

const loadBuyers = async () => {
  await getEmployees({position: 'Media Buyer'}).then(async result => {
    buyerOptions.value = result.map(buyer => ({value: buyer.id, label: buyer.full_name}))
  })
}

const handleCreate = async () => {
  loading.value = true

  try {
    await createAdAccountRenameRequest(structuredClone(formData.value))
  } catch (responseError) {
    return (error.value = responseError.data)
  } finally {
    loading.value = false
  }

  error.value = null

  emit('created')
  notify({type: 'success', text: 'Rename request submitted'})
  closeModal()
}

const onAdAccountChanged = adAccount => {
  formData.value.entity_type = adAccount?.entity_type
  selectedAdAccount.value = adAccount
}

const onReassignBuyerChanged = reassignBuyer => {
  if (buyerOptions.value.length === 0 || reassignBuyer) {
    loadBuyers()
  }

  if (reassignBuyer === false) {
    formData.value.new_buyer = 0
  }
}
</script>

<template>
  <div class="modal fade" :id="modalId" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Rename Account Request</h5>
          <button type="button" class="btn-close" aria-label="Close" @click="closeModal" />
        </div>

        <base-form @on-submitted="handleCreate" ref="form">
          <div class="modal-body">
            <div class="form-group">
              <label>Ad account *</label>
              <the-select-ad-accounts
                v-model="formData.entity_id"
                @changed="onAdAccountChanged"
                :list-by-search="!!userCompanyHash"
                required
              ></the-select-ad-accounts>
            </div>

            <div class="form-group">
              <label for="ad-account-rename-request-name">New name *</label>
              <input
                v-model="formData.name"
                class="form-control"
                id="ad-account-rename-request-name"
                placeholder="Specify new name of ad account"
                required
              />
            </div>

            <div v-if="selectedAdAccount?.company?.is_internal === 1" class="form-group">
              <base-checkbox
                v-model="formData.reassign_buyer"
                @change="onReassignBuyerChanged"
                title="Reassign buyer"
                id="rename-request-buyer"
              ></base-checkbox>
            </div>

            <div v-if="formData.reassign_buyer" class="form-group">
              <label for="meta-ad-account-rename-new-buyer">New buyer</label>
              <the-select v-model="formData.new_buyer" :options="buyerOptions" />
            </div>
            <api-errors v-if="error" :error="error"></api-errors>
          </div>

          <div class="modal-footer justify-content-between">
            <button class="btn btn-primary" :disabled="loading">Submit</button>
            <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
          </div>
        </base-form>
      </div>
    </div>
  </div>
</template>
