<script setup>
import {storeToRefs} from 'pinia'
import {useAuthenticationStore} from '@/stores'
import {computed, onMounted, ref, defineAsyncComponent} from 'vue'
import {mainRouter} from '@/main'
import ImpersonationUserSelect from '@/components/local/ImpersonationUserSelect.vue'
import {isDev, isLocal} from '@/plugins/helpers'
import AdAccountRequestsPlatformsModal from '@/views/ad-account-requests/components/AdAccountRequestsPlatformsModal.vue'
import AdAccountTopUpRequestModal from '@/views/ad-account-requests/top-up/AdAccountTopUpRequestModal.vue'
import AdAccountRenameRequestModal from '@/views/ad-account-requests/rename/AdAccountRenameRequestModal.vue'
import WhiteListModal from '@/views/ad-account-requests/white-lists/components/WhiteListModal.vue'
import ShareRequestModal from '@/views/ad-account-requests/share-permissions/components/ShareRequestModal.vue'
import TheCard from '@/components/TheCard.vue'
import OverdraftModal from '@/views/invoices-main/payments/components/OverdraftModal.vue'
import {useRoute, useRouter} from 'vue-router'
import RequestsDropdown from '@/views/ad-account-requests/components/RequestsDropdown.vue'

const CompanyChangeCommissionModal = defineAsyncComponent(() =>
  import('@/views/companies/components/CompanyChangeCommissionModal.vue'),
)
const CompanyAssignManagerModal = defineAsyncComponent(() =>
  import('@/views/companies/components/CompanyAssignManagerModal.vue'),
)
const CompanyCreditLineModal = defineAsyncComponent(() =>
  import('@/views/companies/components/CompanyCreditLineModal.vue'),
)
const OnboardingClientModal = defineAsyncComponent(() =>
  import('@/views/onboarding/components/OnboardingClientModal.vue'),
)

const {logoutWithRequest} = useAuthenticationStore()
const {
  user,
  isAuthenticated,
  isDeveloper,
  isAgency,
  isAgencyClient,
  isPoint2WebEmployee,
  isMediaPlannerOrCSM,
  isComplianceManager,
  canCreateShortTask,
  canCreateABtestTask,
  canCreateCopyLandingTask,
  canCreateCreativeTask,
  canCreateNewLandingTask,
  canCreateOtherTask,
  canCreateSMMTask,
  canCreateAgencyGeneralTask,
  canUseCreditLineList,
  isAgencyClientAdmin,
  isManager,
  isOnboardingManager,
} = storeToRefs(useAuthenticationStore())

const route = useRoute()
const router = useRouter()
const showTopUpRequestModal = ref(false)
const showSharePermissionModal = ref(false)
const showRenameRequestModal = ref(false)
const platformsModal = ref()
const isMountedComponent = ref(false)
const showAssignManagerModal = ref(false)
let showWhiteListModal = ref(false)
const showCreditLineModal = ref(false)
const showChangeCommissionModal = ref(false)
const showOverdraftModal = ref(false)
const showOnboardingClientModal = ref(false)
const ChangeCommissionRequestType = ref('')

// Computed
const canApplyWhiteList = computed(() => isMediaPlannerOrCSM.value || isAgencyClient.value)

// Methods
onMounted(() => {
  isMountedComponent.value = true
})

const handleLogout = async () => {
  await logoutWithRequest()

  await mainRouter.push({name: 'auth.login'})
}

const openOverdraftModal = () => {
  showOverdraftModal.value = true
}
const openAssignManagerModal = () => {
  showAssignManagerModal.value = true
}
const openAdAccountRequestPlatformsModal = () => {
  platformsModal.value.openModal()
}
const openTopUpAdAccountRequestModal = () => {
  showTopUpRequestModal.value = true
}
const closeTopUpRequestModal = () => {
  showTopUpRequestModal.value = false
}
const openRenameRequestModal = () => {
  showRenameRequestModal.value = true
}
const closeRenameRequestModal = () => {
  showRenameRequestModal.value = false
}
const openShareRequestModal = () => {
  showSharePermissionModal.value = true
}
const closeShareRequestModal = () => {
  showSharePermissionModal.value = false
}
const openWhitelistRequestModal = () => {
  showWhiteListModal.value = true
}
const closeWhitelistRequestModal = () => {
  showWhiteListModal.value = false
}
const openCreditLineModal = () => {
  showCreditLineModal.value = true
}
const openChangeCommissionModal = () => {
  showChangeCommissionModal.value = true

  ChangeCommissionRequestType.value = 'change_commission'
}
const openHandsOnCommissionModal = () => {
  showChangeCommissionModal.value = true

  ChangeCommissionRequestType.value = 'hands_on_commission'
}
const openOnboardingClientModal = () => {
  showOnboardingClientModal.value = true
}
const onOnboardingClientCreated = () => {
  if (route.name === 'onboarding.list') {
    router.go(0)
  }

  router.push({name: 'onboarding'})
}
</script>

<template>
  <the-card class="px-3" lg-border-radius>
    <ad-account-requests-platforms-modal v-if="isMountedComponent" ref="platformsModal" />
    <ad-account-top-up-request-modal
      v-if="isMountedComponent && showTopUpRequestModal"
      @hidden="showTopUpRequestModal = false"
      id="ad-account-top-up-request-header-modal"
      @created="closeTopUpRequestModal"
    />
    <ad-account-rename-request-modal
      v-if="isMountedComponent && showRenameRequestModal"
      @hidden="showRenameRequestModal = false"
      id="header-request-rename-modal"
      @created="closeRenameRequestModal"
    />
    <share-request-modal
      v-if="showSharePermissionModal"
      id="share-request-rename-header-modal"
      @created="closeShareRequestModal"
      @hidden="showSharePermissionModal = false"
    />

    <white-list-modal
      v-if="showWhiteListModal"
      ref="whiteListRequestModal"
      id="header-white-list-modal"
      @created="closeWhitelistRequestModal"
      @hidden="showWhiteListModal = false"
    />

    <company-change-commission-modal
      v-if="showChangeCommissionModal"
      @hidden="showChangeCommissionModal = false"
      id="header-change-commission-modal"
      :request-type="ChangeCommissionRequestType"
    ></company-change-commission-modal>

    <company-assign-manager-modal
      v-if="showAssignManagerModal"
      @hidden="showAssignManagerModal = false"
      id="header-assign-manager-modal"
    ></company-assign-manager-modal>

    <company-credit-line-modal
      id="header-credit-line-modal"
      v-if="showCreditLineModal"
      @hidden="showCreditLineModal = false"
    ></company-credit-line-modal>

    <overdraft-modal
      v-if="showOverdraftModal"
      @hidden="showOverdraftModal = false"
      id="company-modal-overdraft"
    ></overdraft-modal>

    <onboarding-client-modal
      v-if="showOnboardingClientModal"
      @hidden="showOnboardingClientModal = false"
      @created="onOnboardingClientCreated"
      id="header-onboarding-client-modal"
    >
    </onboarding-client-modal>

    <div class="d-flex justify-content-between align-items-center">
      <span class="fs-16-px">
        Hello<template v-if="user.name"
          >, <span class="fw-bold">{{ user.name }}</span>
        </template>
      </span>

      <div class="d-flex align-items-center" v-if="isAuthenticated">
        <div v-if="isPoint2WebEmployee" class="btn-group custom item-noborder me-2" role="group">
          <div class="dropdown-toggle-wrapper">
            <button type="button" class="btn btn-primary dropdown-toggle">New request</button>
          </div>
          <ul class="dropdown-menu">
            <template v-if="isMediaPlannerOrCSM">
              <li>
                <a @click.prevent="openAdAccountRequestPlatformsModal" class="dropdown-item" href="#">
                  <span>Ad account request</span>
                </a>
              </li>
              <li>
                <a @click.prevent="openTopUpAdAccountRequestModal" class="dropdown-item" href="#">
                  <span>New top-up request</span>
                </a>
              </li>
              <li>
                <a @click.prevent="openRenameRequestModal" class="dropdown-item" href="#">
                  <span>New rename request</span>
                </a>
              </li>
              <li>
                <a @click.prevent="openShareRequestModal" class="dropdown-item" href="#">
                  <span>New share request</span>
                </a>
              </li>
              <li v-if="canApplyWhiteList">
                <router-link class="dropdown-item" @click="openWhitelistRequestModal" :to="{name: 'white-list'}">
                  <span>New whitelist request</span>
                </router-link>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
            </template>
            <template v-if="canCreateAgencyGeneralTask">
              <h5 class="dropdown-header">Compliance</h5>
              <li>
                <a class="dropdown-item" :href="$router.resolve({name: 'tasks.agency-general'}).href">
                  <span>Agency Client Pre-Moderation</span>
                </a>
              </li>
              <li>
                <a
                  v-if="!isComplianceManager"
                  class="dropdown-item"
                  :href="$router.resolve({name: 'creatives.list'}).href"
                >
                  <span>Agency Creatives Moderation</span>
                </a>
                <a v-else class="dropdown-item" :href="$router.resolve({name: 'creative-requests.list'}).href">
                  <span>Agency Creative Moderation</span>
                </a>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
            </template>
            <li v-if="isOnboardingManager">
              <span class="dropdown-item" @click="openOnboardingClientModal">
                <span>New client onboarding</span>
              </span>
            </li>
            <li v-if="isAgency">
              <a class="dropdown-item" :href="$router.resolve({name: 'tasks.agency-hands-on'}).href">
                <span>Agency Hands-on</span>
              </a>
            </li>
            <li v-if="isAgency || isOnboardingManager">
              <hr class="dropdown-divider" />
            </li>

            <template v-if="isAgency">
              <li>
                <span class="dropdown-item" @click="openHandsOnCommissionModal">
                  <span>Hands-on commission</span>
                </span>
              </li>
              <li>
                <router-link class="dropdown-item" @click="openAssignManagerModal" :to="{name: 'companies.list'}">
                  <span>Change client manager</span>
                </router-link>
              </li>
              <li>
                <router-link class="dropdown-item" @click="openChangeCommissionModal" :to="{name: 'companies.list'}">
                  <span>Change client commission</span>
                </router-link>
              </li>
            </template>
            <template v-if="isAgency">
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li>
                <span class="dropdown-item" @click="openOverdraftModal">Overdraft request</span>
              </li>
            </template>
            <li v-if="!(canUseCreditLineList && (isLocal() || isDev())) && isAgency">
              <hr class="dropdown-divider" />
            </li>
            <template v-if="canUseCreditLineList && (isLocal() || isDev())">
              <li>
                <router-link class="dropdown-item" @click="openCreditLineModal" :to="{name: 'credit-line.list'}">
                  <span>Credit line request</span>
                </router-link>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
            </template>

            <template v-if="canCreateNewLandingTask || canCreateCopyLandingTask || canCreateABtestTask">
              <li>
                <a class="dropdown-item" :href="$router.resolve({name: 'tasks.new-landing'}).href">
                  <span>Landing Page</span>
                </a>
              </li>

              <li>
                <a class="dropdown-item" :href="$router.resolve({name: 'tasks.landing-copy'}).href">
                  <span>Exact Landing Clone</span>
                </a>
              </li>
              <li>
                <a class="dropdown-item" :href="$router.resolve({name: 'tasks.ab-test'}).href">
                  <span>New A / B test</span>
                </a>
              </li>
            </template>

            <template v-if="canCreateCreativeTask || canCreateShortTask">
              <li>
                <a class="dropdown-item" :href="$router.resolve({name: 'tasks.new-creatives-task'}).href">
                  <span>Creatives request</span>
                </a>
              </li>
              <li>
                <a class="dropdown-item" :href="$router.resolve({name: 'tasks.new-short-task'}).href">
                  <span>Short task</span>
                </a>
              </li>
            </template>

            <template v-if="canCreateSMMTask">
              <li>
                <a class="dropdown-item" :href="$router.resolve({name: 'tasks.new-smm-task'}).href">
                  <span>SMM</span>
                </a>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
            </template>

            <!--            <li v-if="isPoint2WebEmployee">-->
            <!--              <a class="dropdown-item" :href="$router.resolve({name: 'tasks.finance-request'}).href">-->
            <!--                <span>Payment Request</span>-->
            <!--              </a>-->
            <!--            </li>-->

            <li v-if="canCreateOtherTask">
              <a class="dropdown-item" :href="$router.resolve({name: 'tasks.other'}).href">
                <span>Other</span>
              </a>
            </li>
          </ul>
        </div>
        <requests-dropdown class="me-2" v-else />

        <impersonation-user-select v-if="isLocal() || isDev() || isDeveloper || isAgency" />

        <div class="dropdown">
          <button id="dropdownUserButton" aria-expanded="false" class="btn" data-bs-toggle="dropdown" type="button">
            <i class="fas fa-user"></i>
          </button>
          <ul aria-labelledby="dropdownUserButton" class="dropdown-menu">
            <li>
              <router-link :to="{name: 'user'}" class="dropdown-item">My Profile</router-link>
            </li>
            <li>
              <hr class="dropdown-divider" />
            </li>
            <template v-if="isManager || isAgencyClientAdmin">
              <li>
                <router-link :to="{name: 'user.settings'}" class="dropdown-item">Users</router-link>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
            </template>
            <li>
              <button @click="handleLogout" class="dropdown-item">Log out</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </the-card>
</template>
