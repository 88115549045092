<script setup>
import newAppVersionAlert from '@/components/ui/newAppVersionAlert.vue'
import {defineAsyncComponent, onMounted, ref, computed} from 'vue'
import {useRoute} from 'vue-router'
import {storeToRefs} from 'pinia'
import {useAuthenticationStore, usePointBoardStore} from '@/stores'
import {getValidationError, isProduction} from '@/plugins/helpers'
import {useNotification} from '@kyvg/vue3-notification'
import {useCompanyStore} from '@/views/companies/CompanyStore'
import SidebarMenu from '@/components/SidebarMenu.vue'
import TheCard from '@/components/TheCard.vue'
import TheHeaderNew from '@/components/ui/TheHeaderNew.vue'
import MetaTitle from '@/components/ui/MetaTitle.vue'
import ClickupAuthModal from '@/components/global/ClickupAuthModal.vue'
import {useClickupStore} from '@/views/clickup-list/ClickupStore'
import TheBreadCrumbs from '@/components/TheBreadCrumbs.vue'

const CompanyModal = defineAsyncComponent(() => import('@/views/companies/components/CompanyModal.vue'))
const {notify} = useNotification()
const route = useRoute()
const {pingBackend} = usePointBoardStore()

// Refs
const {hasNewVersion} = storeToRefs(usePointBoardStore())
const {showClickupAuthModal} = storeToRefs(useClickupStore())

const {showCompanyModal, companyToEdit} = storeToRefs(useCompanyStore())
const {authUser, isCoreTeam} = storeToRefs(useAuthenticationStore())
const impersonationUserId = localStorage.getItem('original_user_id') * 1
const isImpersonation = ref(impersonationUserId && impersonationUserId !== authUser.value.id)
const {endImpersonation} = useAuthenticationStore()

// Computed
const withCardWrap = computed(() => {
  return !(route.name === 'home')
})
const showLayoutTitle = computed(() => route.meta.type !== 'form')
const showBreadCrumb = computed(() => route.meta?.type === 'form')
const isContentFullHeight = computed(() => route.name === 'onboarding.list')

// Methods
onMounted(async () => {
  runPingBackend
})

const runPingBackend = setInterval(
  () => {
    pingBackend()
  },
  isProduction ? 20 * 1000 : 120 * 1000,
)

const handleEndImpersonation = async () => {
  try {
    await endImpersonation(localStorage.getItem('original_user_id'))
  } catch (error) {
    return notify({type: 'error', text: getValidationError(error.data.message)})
  }

  window.close()

  if (!window.closed) {
    location.reload()
  }
}
</script>

<template>
  <Teleport to="body">
    <CompanyModal
      v-if="showCompanyModal && isCoreTeam"
      :companyToEdit="companyToEdit"
      @hidden="showCompanyModal = false"
      id="layout-company-modal"
    />
  </Teleport>
  <ClickupAuthModal v-if="showClickupAuthModal" @hidden="showClickupAuthModal = false" />

  <div class="main-layout">
    <div v-if="isImpersonation" class="impersonation-alert alert alert-danger py-1 mb-0">
      <span>
        You are acting as <b>{{ authUser?.name || authUser?.email }}</b>
        <span v-if="authUser?.company?.title">
          in <b>{{ authUser?.company?.title }}</b></span
        >
      </span>
      <button @click="handleEndImpersonation()" class="btn btn-sm btn-light bg-white ml-3" type="button">Back</button>
    </div>

    <div class="container-fluid">
      <div class="row flex-nowrap">
        <div class="col-auto px-0">
          <sidebar-menu />
        </div>
        <div :class="['col py-3 overflow-x-hidden', isContentFullHeight ? 'd-flex-full-height vh-100' : '']">
          <the-header-new class="mb-4"></the-header-new>

          <the-bread-crumbs v-if="showBreadCrumb" class="ml-3" />
          <h4 v-if="showLayoutTitle" class="mb-4 ml-3 fw-bold main__title">
            <meta-title />
          </h4>

          <template v-if="withCardWrap">
            <the-card
              :class="['border-radius-lg', isContentFullHeight ? 'flex-1-1-auto full-width card-max-height-vh' : '']"
            >
              <router-view
            /></the-card>
          </template>
          <template v-else>
            <router-view />
          </template>
        </div>
      </div>
    </div>

    <transition name="fade">
      <newAppVersionAlert v-if="hasNewVersion" />
    </transition>
  </div>
</template>

<style lang="scss">
.impersonation-alert {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  border: 0;
}
.card-max-height-vh {
  height: calc(100vh - 200px);
}
</style>
