<script setup>
import WhiteListModal from '@/views/ad-account-requests/white-lists/components/WhiteListModal.vue'
import ShareRequestModal from '@/views/ad-account-requests/share-permissions/components/ShareRequestModal.vue'
import AdAccountTopUpRequestModal from '@/views/ad-account-requests/top-up/AdAccountTopUpRequestModal.vue'
import AdAccountRenameRequestModal from '@/views/ad-account-requests/rename/AdAccountRenameRequestModal.vue'
import {ref} from 'vue'
import AdAccountRequestsPlatformsModal from '@/views/ad-account-requests/components/AdAccountRequestsPlatformsModal.vue'
import {useRouter} from 'vue-router'

const router = useRouter()
const showWhiteListModal = ref(false)
const showTopUpRequestModal = ref(false)
const showRenameRequestModal = ref(false)
const showSharePermissionModal = ref(false)
const platformsModal = ref()

// Methods
const openTopUpAdAccountRequestModal = () => {
  showTopUpRequestModal.value = true
}
const openRenameRequestModal = () => {
  showRenameRequestModal.value = true
}
const openShareRequestModal = () => {
  showSharePermissionModal.value = true
}
const openWhitelistRequestModal = () => {
  showWhiteListModal.value = true
}
const openAccountRequestPlatformsModal = () => {
  platformsModal.value.openModal()
}
const onShareRequestCreated = () => {
  router.push({name: 'share-permissions'})
}
const onRenameRequestCreated = () => {
  router.push({name: 'ad-account-requests.rename.list'})
}
const onTopupRequestCreated = () => {
  router.push({name: 'ad-account-requests.top-up.list'})
}
const onWhiteRequestCreated = () => {
  router.push({name: 'white-list'})
}
</script>

<template>
  <ad-account-top-up-request-modal
    v-if="showTopUpRequestModal"
    @hidden="showTopUpRequestModal = false"
    @created="onTopupRequestCreated"
    id="dropdown-ad-account-top-up-request-modal"
  />
  <ad-account-rename-request-modal
    v-if="showRenameRequestModal"
    @hidden="showRenameRequestModal = false"
    @created="onRenameRequestCreated"
    id="dropdown-ad-account-rename-request-modal"
  />
  <share-request-modal
    v-if="showSharePermissionModal"
    @hidden="showSharePermissionModal = false"
    id="dropdown-share-request-modal"
    @created="onShareRequestCreated"
  />
  <ad-account-requests-platforms-modal ref="platformsModal" id="dropdown-requests-platforms-modal" />

  <white-list-modal
    v-if="showWhiteListModal"
    ref="whiteListRequestModal"
    id="dropdown-requests-white-list-modal"
    @hidden="showWhiteListModal = false"
    @created="onWhiteRequestCreated"
  />
  <div class="btn-group custom" role="group" v-bind="$attrs">
    <div class="dropdown-toggle-wrapper">
      <button type="button" class="btn btn-primary dropdown-toggle">Create Request</button>
    </div>
    <ul class="dropdown-menu">
      <li>
        <span @click.prevent="openAccountRequestPlatformsModal" class="dropdown-item">New Ad Account Request</span>
      </li>
      <li>
        <span class="dropdown-item" @click="openTopUpAdAccountRequestModal">Top-Up Account Request</span>
      </li>
      <li>
        <span class="dropdown-item" @click="openRenameRequestModal">Rename Account Request</span>
      </li>
      <li>
        <span class="dropdown-item" @click="openShareRequestModal">Share Account Request</span>
      </li>
      <li>
        <span class="dropdown-item" @click="openWhitelistRequestModal">White List Request</span>
      </li>
      <li>
        <router-link class="dropdown-item" :to="{name: 'creatives.profile'}">Creative Moderation Request</router-link>
      </li>
    </ul>
  </div>
</template>
