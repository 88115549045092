<script setup>
import {useRoute} from 'vue-router'
import {computed, ref, onMounted} from 'vue'
import {notify} from '@kyvg/vue3-notification'
import {useUserStore} from '@/stores/UserStore'
import {useAuthenticationStore} from '@/stores'
import {storeToRefs} from 'pinia'

const {
  isGoogleUser,
  isPoint2WebEmployee,
  canWorkWithCreatives,
  hasAccessToCompaniesPage,
  canUseInvoices,
  isAgencyClient,
  isDeveloperOrInternalCompany,
  fromSFCompany,
  isDeveloper,
  canWorkWithOnboardings,
  isFinance,
  isComplianceManager,
  isAgency,
  canSeeDashboard,
  isCoreTeam,
} = storeToRefs(useAuthenticationStore())
const {getUserManager} = useUserStore()

// Refs
const route = useRoute()
const managerLoading = ref(false)
const hasNoManager = ref(false)
const managerInfo = ref({
  full_name: '',
  position: '',
  photo_path: null,
  email: '',
  skype: '',
  telegram: '',
})

// Computed
const routeName = computed(() => route.name)
const parentRouteName = computed(() => route?.meta?.parent)
const requestsRouteName = computed(() => {
  if (isFinance.value) {
    return 'ad-account-requests.top-up.list'
  }

  if (!isPoint2WebEmployee) {
    return 'my-requests.list'
  }

  return 'ad-account-requests.accounts.list'
})

// Methods
onMounted(() => {
  if (!isPoint2WebEmployee.value) {
    getManagerInfo()
  }
})

const getManagerInfo = async () => {
  managerLoading.value = true

  try {
    const manager = await getUserManager()

    if (!manager || manager.length === 0) {
      hasNoManager.value = true
    } else {
      managerInfo.value = manager
    }
  } catch (error) {
    hasNoManager.value = true

    notify({type: 'error', text: error.data.message})
  } finally {
    managerLoading.value = false
  }
}
</script>

<template>
  <div class="sidebar-menu bg-white pb-5">
    <div class="position-sticky top-0">
      <div class="p-4">
        <img src="@/assets/images/sidebar/pb_logo.webp" alt="pointboard" class="max-w-150-px d-block" />
      </div>
      <hr class="m-0" />

      <ul class="nav nav-pills flex-column pt-3" id="menu">
        <li v-if="!isCoreTeam" class="nav-item">
          <router-link
            :class="[
              'nav-link d-flex align-items-center px-4 py-3',
              [routeName, parentRouteName].includes('home') ? 'active' : '',
            ]"
            :to="{name: 'home'}"
            active-class="active"
          >
            <span class="nav-icon">
              <i class="fas fa-home"></i>
            </span>
            <span class="ml-3 nav-text">Home Page</span>
          </router-link>
        </li>
        <li v-if="!isPoint2WebEmployee && canSeeDashboard" class="nav-item">
          <router-link
            :to="{name: 'dashboard'}"
            :class="[
              'nav-link d-flex align-items-center px-4 py-3',
              [routeName, parentRouteName].includes('dashboard') ? 'active' : '',
            ]"
            active-class="active"
          >
            <span class="nav-icon">
              <i class="fas fa-chart-line"></i>
            </span>

            <span class="ml-3 nav-text">Dashboard</span>
          </router-link>
        </li>
        <li v-if="!isGoogleUser" class="nav-item">
          <router-link
            :class="[
              'nav-link d-flex align-items-center px-4 py-3',
              [routeName, parentRouteName].includes('ad-account-requests.accounts.list') ? 'active' : '',
            ]"
            :to="{name: requestsRouteName}"
            active-class="active"
          >
            <span class="nav-icon">
              <i class="far fa-edit"></i>
            </span>

            <span class="ml-3 nav-text">Requests</span>
          </router-link>
        </li>
        <li v-if="canWorkWithCreatives && !isAgency && !isComplianceManager" class="nav-item">
          <router-link
            :to="{name: 'creatives.list'}"
            :class="[
              'nav-link d-flex align-items-center justify-content-between px-4 py-3',
              [routeName, parentRouteName].includes('creatives.list') ? 'active' : '',
            ]"
            active-class="active"
          >
            <div class="d-flex align-items-center">
              <span class="nav-icon">
                <i class="fas fa-photo-video"></i>
              </span>
              <span class="ml-3 nav-text">Creatives Moderation</span>
            </div>
            <div class="small-badge-light-border ml-2">Beta</div>
          </router-link>
        </li>
        <li v-if="hasAccessToCompaniesPage" class="nav-item">
          <router-link
            :to="{name: 'companies.list'}"
            :class="[
              'nav-link d-flex align-items-center px-4 py-3',
              [routeName, parentRouteName].includes('companies.list') ? 'active' : '',
            ]"
            active-class="active"
          >
            <span class="nav-icon">
              <i class="fas fa-sitemap"></i>
            </span>

            <span class="ml-3 nav-text">Companies</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{name: 'assets.ad-accounts.list'}"
            :class="[
              'nav-link d-flex align-items-center px-4 py-3',
              [routeName, parentRouteName].includes('assets.companies.list') ? 'active' : '',
            ]"
            active-class="active"
          >
            <span class="nav-icon">
              <i class="fas fa-address-card"></i>
            </span>

            <span class="ml-3 nav-text">Ad Accounts</span>
          </router-link>
        </li>

        <li v-if="canUseInvoices" class="nav-item">
          <router-link
            :to="{name: isAgencyClient ? 'payments.daily_balance' : 'invoices.list'}"
            :class="[
              'nav-link d-flex align-items-center px-4 py-3',
              [routeName, parentRouteName].includes('invoices-main') ? 'active' : '',
            ]"
            active-class="active"
          >
            <span class="nav-icon">
              <i class="fas fa-coins"></i>
            </span>

            <span class="ml-3 nav-text">{{ isAgencyClient ? 'Financial Overview' : 'Finance' }}</span>
          </router-link>
        </li>
        <li v-if="isDeveloperOrInternalCompany && !isGoogleUser" class="nav-item">
          <router-link
            :to="{name: 'campaigns.list'}"
            :class="[
              'nav-link d-flex align-items-center px-4 py-3',
              [routeName, parentRouteName].includes('tracker') ? 'active' : '',
            ]"
            active-class="active"
          >
            <span class="nav-icon">
              <i class="fas fa-ad"></i>
            </span>

            <span class="ml-3 nav-text">Tracker</span>
          </router-link>
        </li>
        <li v-if="isPoint2WebEmployee" class="nav-item">
          <router-link
            :to="{name: 'reports'}"
            active-class="active"
            :class="[
              'nav-link d-flex align-items-center px-4 py-3',
              [routeName, parentRouteName].includes('reports') ? 'active' : '',
            ]"
          >
            <span class="nav-icon">
              <i class="fas fa-chart-pie"></i>
            </span>

            <span class="ml-3 nav-text">Reports</span>
          </router-link>
        </li>
        <li v-if="isPoint2WebEmployee" class="nav-item">
          <router-link
            :to="{
              name: fromSFCompany || isDeveloper ? 'domains.list' : 'subscriptions.list',
            }"
            :class="[
              'nav-link d-flex align-items-center px-4 py-3',
              [routeName, parentRouteName].includes('tools') ? 'active' : '',
            ]"
            active-class="active"
          >
            <span class="nav-icon">
              <i class="fas fa-tools"></i>
            </span>

            <span class="ml-3 nav-text">Tools</span>
          </router-link>
        </li>
        <li v-if="canWorkWithOnboardings" class="nav-item">
          <router-link
            :to="{name: 'onboarding'}"
            :class="[
              'nav-link d-flex align-items-center px-4 py-3',
              [routeName, parentRouteName].includes('onboarding') ? 'active' : '',
            ]"
            active-class="active"
          >
            <span class="nav-icon">
              <i class="fas fa-user-plus"></i>
            </span>

            <span class="ml-3 nav-text">Onboarding</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="isPoint2WebEmployee">
          <router-link
            :to="{name: 'clickup.list'}"
            :class="[
              'nav-link d-flex align-items-center px-4 py-3',
              [routeName, parentRouteName].includes('clickup.list') ? 'active' : '',
            ]"
            active-class="active"
          >
            <span class="nav-icon">
              <i class="fas fa-tasks"></i>
            </span>

            <span class="ml-3 nav-text">ClickUp</span>
          </router-link>
        </li>
        <template v-if="!isPoint2WebEmployee">
          <li class="nav-item">
            <a
              href="https://point2web.com/creative-studio/"
              rel="nofollow noopener noreferrer"
              target="_blank"
              class="nav-link d-flex align-items-center justify-content-between px-4 py-3"
            >
              <div class="d-flex align-items-center">
                <span class="nav-icon">
                  <i class="far fa-lightbulb"></i>
                </span>
                <span class="ml-3 nav-text">Creative Studio</span>
              </div>
              <i class="fas fa-external-link-alt pl-15-px"></i>
            </a>
          </li>
          <li class="nav-item">
            <a
              href="https://point2web.com/performance-network/"
              rel="nofollow noopener noreferrer"
              target="_blank"
              class="nav-link d-flex align-items-center justify-content-between px-4 py-3"
            >
              <div class="d-flex align-items-center">
                <span class="nav-icon">
                  <i class="fas fa-tachometer-alt"></i>
                </span>

                <span class="ml-3 nav-text">Performance Network</span>
              </div>
              <i class="fas fa-external-link-alt pl-15-px"></i>
            </a>
          </li>
        </template>
        <!--      <li :class="['nav-item', [routeName, parentRouteName].includes('invoices-main') ? 'active' : '']">-->
        <!--        <div class="btn-group d-block dropright">-->
        <!--          <button-->
        <!--            type="button"-->
        <!--            class="btn dropdown-toggle d-flex align-items-center px-4 w-100"-->
        <!--            data-toggle="dropdown"-->
        <!--            aria-haspopup="true"-->
        <!--            aria-expanded="false"-->
        <!--          >-->
        <!--            <span class="nav-icon">-->
        <!--              <img src="@/assets/images/sidebar/finance.svg" alt="faq" />-->
        <!--            </span>-->
        <!--            <span class="nav-icon-hover d-none">-->
        <!--              <img src="@/assets/images/sidebar/finance-orange.svg" alt="faq" />-->
        <!--            </span>-->

        <!--            <span class="ml-3  nav-text">Financial Overview</span>-->
        <!--          </button>-->
        <!--          <div-->
        <!--            class="dropdown-menu w-200-px p-0"-->
        <!--            x-placement="right-start"-->
        <!--            style="position: absolute; top: 0; left: 100%"-->
        <!--          >-->
        <!--            <router-link :to="{name: 'invoices.list'}" class="dropdown-item"> Proforma Invoices</router-link>-->

        <!--            <hr class="dropdown-divider m-0" />-->
        <!--            <router-link :to="{name: 'payments.list'}" class="dropdown-item"> Payments</router-link>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--      </li>-->

        <!--      <li class="nav-item">-->
        <!--        <a href="#" class="nav-link d-flex align-items-center px-4">-->
        <!--          <span class="nav-icon">-->
        <!--            <img src="@/assets/images/sidebar/faq.svg" alt="faq" />-->
        <!--          </span>-->
        <!--          <span class="nav-icon-hover d-none">-->
        <!--            <img src="@/assets/images/sidebar/faq-orange.svg" alt="faq" />-->
        <!--          </span>-->

        <!--          <span class="ml-3  nav-text">FAQ</span>-->
        <!--        </a>-->
        <!--      </li>-->
      </ul>

      <div class="pt-3" v-if="!isPoint2WebEmployee && !hasNoManager">
        <hr class="m-0 pb-3" />
        <div class="px-4">
          <p class="fw-bold">I'm here to help you:</p>
          <div class="d-flex gap-3 align-items-center">
            <div v-if="managerInfo.photo_path" class="b-radius-50 w-80-px overflow-hidden border-2-grey">
              <img :src="managerInfo.photo_path" :alt="managerInfo.full_name" class="d-block max-w-100 w-100" />
            </div>
            <div>
              <p class="fw-bold mb-0">{{ managerInfo.full_name }}</p>
              <p class="mb-3">{{ managerInfo.position }}</p>
              <div class="d-flex gap-2 align-items-center">
                <a
                  v-if="managerInfo.skype"
                  :href="`skype:${managerInfo.skype}?chat`"
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  class="d-block b-radius-50 h-30-px w-30-px"
                >
                  <img src="@/assets/images/sidebar/socials/skype.webp" alt="skype" class="d-block max-w-100 w-100" />
                </a>
                <a
                  v-if="managerInfo.telegram"
                  :href="managerInfo.telegram"
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  class="d-block b-radius-50 h-30-px w-30-px"
                >
                  <img
                    src="@/assets/images/sidebar/socials/telegram.webp"
                    alt="telegram"
                    class="d-block max-w-100 w-100"
                  />
                </a>
                <a
                  v-if="managerInfo.email"
                  :href="`mailto:${managerInfo.email}`"
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  class="d-block b-radius-50 h-30-px w-30-px"
                >
                  <img src="@/assets/images/sidebar/socials/email.webp" alt="email" class="d-block max-w-100 w-100" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sidebar-menu {
  min-height: calc(100vh - 32px);
  height: 100%;
}

.nav-link {
  color: #1d1c1c;
}

.nav-text {
  font-weight: 400;
  transition: all 0.1s ease;
}

.nav-icon {
  text-align: center;
  width: 25px;
  font-size: 20px;
  color: #b1b1b1;
}

.nav-pills .nav-link.active {
  background: #f2f4f8;
  color: #1d1c1c;
  position: relative;

  & .nav-text {
    font-weight: 700;
  }

  &:after {
    content: '';
    display: block;
    height: 100%;
    width: 4px;
    background-color: #fb6639;
    border-radius: 25px;
    opacity: 1;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    transition: opacity 0.3s;
  }

  & .nav-icon {
    color: #fb6639;
  }
}

.nav-item {
  min-width: 250px;
  position: relative;
  z-index: 1;

  &.active {
    background: #f2f4f8;
    color: #1d1c1c;
    position: relative;

    & .nav-text {
      font-weight: 700;
    }

    & .nav-icon {
      color: #fb6639;
    }

    & .btn-group {
      position: relative;
      z-index: 1;

      &:after {
        content: '';
        display: block;
        height: 100%;
        width: 4px;
        background-color: #fb6639;
        border-radius: 25px;
        opacity: 1;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
      }
    }
  }

  &:after {
    content: '';
    display: block;
    height: 100%;
    width: 4px;
    background-color: #fb6639;
    border-radius: 25px;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    transition: opacity 0.3s;
  }

  &:hover {
    background: #f2f4f8;

    &:after {
      opacity: 1;
    }
  }

  &:hover .nav-icon {
    color: #fb6639;
  }

  &:hover .nav-text {
    font-weight: 700;
  }
}

.border-2-grey {
  border: 2px solid #464646;
}
</style>
