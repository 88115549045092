<script setup>
import {defineProps, ref, computed, onMounted, watch, defineModel, defineExpose} from 'vue'
import {debounce} from '@/helpers/debounce'
import {useNotification} from '@kyvg/vue3-notification'
import {useBigoBusinessManagerStore} from '@/views/assets/business-managers/stores/BigoBusinessManagerStore'
import {useBingBusinessManagerStore} from '@/views/assets/business-managers/stores/BingBusinessManagerStore'
import {useGoogleBusinessManagerStore} from '@/views/assets/business-managers/stores/GoogleBusinessManagerStore'
import {useNewsbreakBusinessManagerStore} from '@/views/assets/business-managers/stores/NewsbreakBusinessManagerStore'
import {useOutbrainBusinessManagerStore} from '@/views/assets/business-managers/stores/OutbrainBusinessManagerStore'
import {useRevcontentBusinessManagerStore} from '@/views/assets/business-managers/stores/RevcontentBusinessManagerStore'
import {useSnapchatBusinessManagerStore} from '@/views/assets/business-managers/stores/SnapchatBusinessManagerStore'
import {useTaboolaBusinessManagerStore} from '@/views/assets/business-managers/stores/TaboolaBusinessManagerStore'
import {useTiktokBusinessManagerStore} from '@/views/assets/business-managers/stores/TiktokBusinessManagerStore'
import {useMetaBusinessManagerStore} from '@/views/ad-account-requests/accounts/meta/MetaBusinessManagerStore'
import InputPreloader from '@/components/ui/InputPreloader.vue'
import TheSelectMultiple from '@/components/ui/TheSelectMultiple.vue'
import TheSelect from '@/components/ui/TheSelect.vue'
import {useRoute} from 'vue-router'

const {notify} = useNotification()
const {getBigoBusinessManagers} = useBigoBusinessManagerStore()
const {getBingBusinessManagers} = useBingBusinessManagerStore()
const {getGoogleBusinessManagers} = useGoogleBusinessManagerStore()
const {getNewsbreakBusinessManagers} = useNewsbreakBusinessManagerStore()
const {getOutbrainBusinessManagers} = useOutbrainBusinessManagerStore()
const {getRevcontentBusinessManagers} = useRevcontentBusinessManagerStore()
const {getSnapchatBusinessManagers} = useSnapchatBusinessManagerStore()
const {getTaboolaBusinessManagers} = useTaboolaBusinessManagerStore()
const {getTiktokBusinessManagers} = useTiktokBusinessManagerStore()
const {getFacebookBusinessManagers} = useMetaBusinessManagerStore()

const modelValue = defineModel({required: true})
const props = defineProps({
  platform: {
    type: String,
    required: true,
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: 'BM - Enter from 2 characters to search',
  },
  selectedBusinessManager: {},
  listBySearch: {
    type: Boolean,
    default: false,
  },
  withAppended: {
    type: Boolean,
    default: true,
  },
  payload: {
    type: Object,
    default: null,
  },
  mode: {
    type: String,
    default: 'single',
  },
})

// Refs
const route = useRoute()
const businessManagers = ref([])
const loadingBM = ref(false)

// Computed
const formatBusinessManagerTitle = (businessManager, withCount = true) => {
  const idPart = businessManager.external_id ? ` ID: ${businessManager.external_id}` : ''
  const adAccountPart = ` (${businessManager.not_draft_ad_accounts_count})`

  return withCount ? businessManager.title + idPart + adAccountPart : businessManager.title + idPart
}

const isAdAccountRequestPage = computed(() => {
  return route?.meta?.type === 'form' && route?.meta?.parent === 'ad-account-requests.accounts.list'
})

const businessManagersOptions = computed(() =>
  businessManagers.value.map(businessManager => ({
    value: businessManager.hash,
    label: formatBusinessManagerTitle(businessManager),
    disabled: isAdAccountRequestPage.value
      ? businessManager.not_draft_ad_accounts_count >= businessManager.ad_accounts_limit
      : undefined,
  })),
)

// Methods
onMounted(async () => {
  await init()
})

const init = async () => {
  if (props.selectedBusinessManager?.hash !== null) {
    await handleSelectedBusinessManager()

    return
  }

  if (!props.listBySearch) {
    return
  }

  await requestGetBusinessManagers()
}
const getBusinessManagers = async search => {
  switch (props.platform) {
    case 'bigo':
      return await getBigoBusinessManagers({search})

    case 'bing':
      return await getBingBusinessManagers({search})

    case 'google':
      return await getGoogleBusinessManagers({search})

    case 'newsbreak':
      return await getNewsbreakBusinessManagers({search})

    case 'outbrain':
      return await getOutbrainBusinessManagers({search})

    case 'revcontent':
      return await getRevcontentBusinessManagers({search})

    case 'snapchat':
      return await getSnapchatBusinessManagers({search})

    case 'taboola':
      return await getTaboolaBusinessManagers({search})

    case 'tiktok':
      return await getTiktokBusinessManagers({search})

    case 'meta':
    case 'facebook':
      // eslint-disable-next-line no-case-declarations
      const params = {search}
      if (props?.payload?.company_hash !== null && props?.payload?.company_hash !== undefined) {
        params.company_hash = props.payload.company_hash
      }

      return await getFacebookBusinessManagers(params)
  }
}

const handleSelectedBusinessManager = async () => {
  loadingBM.value = true

  await requestGetBusinessManagers(props.selectedBusinessManager?.title)
}

const requestGetBusinessManagers = async search => {
  try {
    let response = await getBusinessManagers(search)
    businessManagers.value = response.data
  } catch (responseError) {
    if (responseError.data !== undefined) {
      notify({type: 'error', text: responseError.data.message})
    }
  } finally {
    loadingBM.value = false
  }
}

const checkSearchValue = async searchValue => {
  if (searchValue.length >= 2) {
    await reloadBusinessManagers(searchValue)
  }
}

const reloadBusinessManagers = debounce(async searchValue => {
  await requestGetBusinessManagers(searchValue)
}, 300)

watch(
  () => props.selectedBusinessManager,
  async () => {
    if (props.selectedBusinessManager?.hash !== null) {
      await handleSelectedBusinessManager()
    }
  },
)

defineExpose({checkSearchValue})
</script>

<template>
  <input-preloader :show-preloader="loadingBM" name="business manager">
    <span v-bind="$attrs">
      <the-select
        v-if="props.mode === 'single'"
        :class="[modelValue && props.withAppended ? 'with-appended' : '']"
        v-model="modelValue"
        :options="businessManagersOptions"
        :disabled="disabled"
        @search-change="checkSearchValue"
        :placeholder="placeholder"
        :allow_clear="false"
        mode="single"
        required
      ></the-select>
      <the-select-multiple
        v-else
        :class="[modelValue && props.withAppended ? 'with-appended' : '']"
        v-model="modelValue"
        :options="businessManagersOptions"
        :disabled="disabled"
        @search-change="checkSearchValue"
        :placeholder="placeholder"
        :allow_clear="false"
        :mode="mode"
        required
      ></the-select-multiple>
    </span>
    <slot></slot>
  </input-preloader>
</template>
