<script setup>
import {defineEmits, defineProps} from 'vue'

defineProps({
  modelValue: {},
  title: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  bold: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['change', 'update:modelValue'])

const onChange = value => {
  emit('update:modelValue', value.target.checked)
  emit('change', value.target.checked)
}
</script>

<template>
  <div class="d-flex align-items-start">
    <input
      :checked="modelValue"
      class="form-check-input"
      :id="id"
      @change="onChange"
      :disabled="disabled || readonly"
      type="checkbox"
      :required="required"
    />
    <label :class="['ms-1 pointer', bold ? 'fw-bold' : '']" :for="id">{{ title }}</label>
  </div>
</template>
