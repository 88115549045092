<script setup>
import {defineEmits, defineProps, ref, computed, onMounted} from 'vue'
import {notify} from '@kyvg/vue3-notification'
import BaseForm from '@/components/BaseForm.vue'
import ApiErrors from '@/components/ui/ApiErrors.vue'
import TheSelectAdAccounts from '@/components/ui/TheSelectAdAccounts.vue'
import {useAdAccountTopUpRequestStore} from '@/views/ad-account-requests/top-up/AdAccountTopUpRequestStore'
import {storeToRefs} from 'pinia'
import {useAuthenticationStore} from '@/stores'
import BaseCheckbox from '@/components/BaseCheckbox.vue'
import TheModal from '@/components/ui/TheModal.vue'

let {createAdAccountTopUpRequest} = useAdAccountTopUpRequestStore()
const {userCompanyHash, canUseTopUpAsap} = storeToRefs(useAuthenticationStore())

// Emits & props
const props = defineProps({
  id: {
    type: String,
    default: null,
  },
})
let emit = defineEmits(['created', 'updated', 'hidden'])

// Refs
const modalId = props.id ?? 'ad-account-top-up-request-modal'
const theModal = ref()
const defaultFormData = {entity_id: null, entity_type: null, amount: null, is_asap: false, is_withdraw: false}
const formData = ref([structuredClone(defaultFormData)])
const error = ref(null)
const loading = ref(false)

// Computed
const canAddItem = computed(() => {
  return formData.value.every(item => item.entity_id && item.amount !== null && item.amount !== '')
})

// Methods
onMounted(() => {
  openModal()
})

const openModal = () => {
  formData.value = [structuredClone(defaultFormData)]
  theModal.value.handleOpenModal()
}

const closeModal = () => {
  theModal.value.handleCloseModal()
}

const handleCreate = async () => {
  loading.value = true

  try {
    await createAdAccountTopUpRequest(getRequestParams())
  } catch (responseError) {
    return (error.value = responseError.data)
  } finally {
    loading.value = false
  }

  emit('created')
  notify({type: 'success', text: 'Top-up request submitted'})
  closeModal()
}

const getRequestParams = () => {
  return structuredClone(formData.value).map(item => {
    return {
      ...item,
      amount: item.is_withdraw ? Math.abs(item.amount) * -1 : item.amount,
    }
  })
}

const onAdAccountChanged = (adAccount, index) => {
  formData.value[index].entity_type = adAccount?.entity_type
}

const addNewItem = () => {
  formData.value.push(structuredClone(defaultFormData))
}

const removeItem = index => {
  formData.value.splice(index, 1)
}

const emitHidden = () => {
  emit('hidden')
}
</script>

<template>
  <TheModal ref="theModal" @hidden="emitHidden" class="modal-lg" :id="modalId" title="Top-up Account Request">
    <base-form @on-submitted="handleCreate">
      <div class="modal-body">
        <div class="row">
          <div class="col-6">
            <label>Ad account *</label>
          </div>
          <div class="col-3">
            <label>Sum *</label>
          </div>
          <div class="col-auto"></div>
          <div class="col-auto"></div>
        </div>
        <div v-for="(item, index) in formData" :key="index" class="form-group row align-items-center">
          <div class="col-6">
            <the-select-ad-accounts
              v-model="item.entity_id"
              @changed="onAdAccountChanged($event, index)"
              :list-by-search="!!userCompanyHash"
              :payload="{with_can_has_top_up: 1}"
              required
            ></the-select-ad-accounts>
          </div>

          <div class="col-3">
            <div class="input-group">
              <span class="input-group-text">$</span>
              <input v-model="item.amount" class="form-control" type="number" step="0.01" required />
            </div>
          </div>

          <div class="col-auto ps-0">
            <base-checkbox
              v-model="item.is_withdraw"
              title="Withdraw"
              :id="`top-up-${modalId}-${index + 1}-is_withdraw`"
            ></base-checkbox>
            <base-checkbox
              v-if="canUseTopUpAsap"
              v-model="item.is_asap"
              title="ASAP"
              :id="`top-up-${modalId}-${index + 1}-asap`"
            ></base-checkbox>
          </div>

          <div class="col-auto ps-0">
            <button v-show="formData.length > 1" class="btn" type="button" @click="removeItem(index)">
              <span class="fa fa-trash text-muted"></span>
            </button>
          </div>
        </div>

        <button @click="addNewItem" :disabled="!canAddItem" class="btn btn-secondary btn-sm" type="button">Add</button>

        <api-errors v-if="error" :error="error"></api-errors>
      </div>
      <div class="modal-footer justify-content-between">
        <button class="btn btn-primary" :disabled="loading">Submit</button>
        <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
      </div>
    </base-form>
  </TheModal>
</template>
